import React from "react";

import { faEye } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get } from "lodash";

import {
  ActionButton,
  ActionButtonContainer,
} from "components/AgGrid/ActionButton";
import { BusinessRelationshipDialog } from "components/BusinessForm/Relationships/Form";

import { businessRelationsRateTypes } from "constants/businesses";

import { formatPercentage, getDollarPriceStringFromCents } from "lib/index";
import { openEditBusinessModal } from "lib/navigation";

import { useBoolean } from "hooks";

export const formatRelationshipRate = relationship => {
  if (relationship.rateType === businessRelationsRateTypes.PERCENTAGE) {
    return `${formatPercentage(relationship.rateValue)}`;
  } else if (relationship.rateType === businessRelationsRateTypes.HEAD) {
    return `$${getDollarPriceStringFromCents(relationship.rateValue)}/head`;
  } else {
    return null;
  }
};

export const formatRelationship = relationship => {
  const rateText = formatRelationshipRate(relationship);

  if (rateText) {
    return `${relationship.relationType} (${rateText})`;
  } else {
    return relationship.relationType;
  }
};

export const BusinessRelationshipRenderer = ({ node, colDef, data }) => {
  const value = get(data, colDef.field);

  if (node.group || !value) {
    return null;
  }

  return value.map(relationship => {
    return (
      <ActionButton
        key={relationship.id}
        onClick={() => openEditBusinessModal(relationship.relatedToId)}
      >
        {formatRelationship(relationship)}
      </ActionButton>
    );
  });
};

export const BusinessRelationshipActionRender = ({ data, context }) => {
  const [isOpen, handleOpen, handleClose] = useBoolean(false);

  if (!data) {
    return null;
  }

  const handleSubmit = values => {
    context.saveRelationship(values);
    handleClose();
  };

  const { relationship } = data;

  const handleDelete = () => {
    context.deleteRelationship(relationship.id);
    handleClose();
  };

  return (
    <>
      <ActionButtonContainer>
        <ActionButton onClick={handleOpen} data-tour="editRelation">
          <FontAwesomeIcon icon={faEye} />
          &nbsp;View/Edit
        </ActionButton>
      </ActionButtonContainer>
      {isOpen && (
        <BusinessRelationshipDialog
          isOpen={isOpen}
          initialValues={relationship}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
};
