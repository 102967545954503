import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import { PropTypes } from "prop-types";
import styled from "styled-components/macro";

import Clickable from "components/Button/Clickable";

const ButtonWrapper = styled(Clickable)(
  ({ color, disabled, theme, inline }) => `
  opacity: ${disabled ? "0.66" : "initial"};
  margin: ${theme.space[1]}px ${inline ? 0 : theme.space[2]}px;
  color: ${disabled ? theme.colors.gray78 : theme.colors[color]};
  display: flex;
  align-items: center;
  &:hover {
    ${disabled ? "" : "opacity: 0.66;"}
  }
`,
);

const ButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 6px;
`;

const IconTextButton = ({
  children,
  icon,
  color,
  style,
  onClick,
  as,
  to,
  iconSize = 16,
  textSize,
  tooltip,
  disabled,
  dataTour,
  inline = false,
  target,
}) => {
  const button = (
    <ButtonWrapper
      as={as}
      to={to}
      onClick={onClick}
      color={color}
      style={style}
      target={target}
      type="button"
      disabled={disabled}
      inline={inline ? 1 : 0}
      data-tour={dataTour}
    >
      {icon && <ButtonIcon icon={icon} style={{ fontSize: iconSize }} />}
      <span style={{ fontSize: textSize }}>{children}</span>
    </ButtonWrapper>
  );
  return tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button;
};

IconTextButton.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  icon: PropTypes.any,
  inline: PropTypes.bool,
  tooltip: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  as: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]),
  to: PropTypes.string,
  textSize: PropTypes.number,
  disabled: PropTypes.bool,
};

IconTextButton.defaultProps = {
  color: "primary",
  textSize: 12,
};

export default IconTextButton;

export const LargeIconTextButton = props => (
  <IconTextButton {...props} iconSize={20} />
);

export const TableIconTextButton = props => (
  <IconTextButton
    {...props}
    iconSize={14}
    style={{
      ...props.style,
      textDecoration: "underline",
      textTransform: "uppercase",
    }}
    textSize={14}
  />
);
