import React from "react";

import { faCircleDot, faCircle } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "@material-ui/core";

import { SmallFaIcon } from "components/FaIcon";
import { Row } from "components/Layout";
import { Text } from "components/Text";

import { makeCommaSeparatedString, pluralize } from "lib/pluralize";

import { useGetMissingFieldWarnings } from "hooks/useGetMissingFieldWarnings";
import {
  MissingFieldsIndicatorBaseProps,
  MissingFieldsIndicatorProps,
} from "./types";

export function MissingFieldsIndicatorBase(
  props: MissingFieldsIndicatorBaseProps,
): React.JSX.Element {
  const { warnings, label, overrideNoMissingField = false } = props;
  // We can use the override when displaying warnings on a single field
  // For example, check if the warning array contains our field,
  // if it doesn't then we can overide with a green status
  if (overrideNoMissingField) {
    warnings.length = 0;
  }

  let tooltipText = "";
  if (warnings.length > 0) {
    tooltipText = `The Required ${label} ${pluralize("Field", warnings.length)}: ${makeCommaSeparatedString(warnings)} ${warnings.length > 1 ? "are" : "is"} missing`;
  } else if (!overrideNoMissingField) {
    tooltipText = `All Required ${label} Fields Entered`;
  }

  return (
    <Row gridGap={1} padding={1} alignCenter marginRight={1}>
      <Text fontSize="beta" color="titleLight">
        {label}
      </Text>
      <Tooltip title={tooltipText}>
        <SmallFaIcon
          icon={warnings.length > 0 ? faCircleDot : faCircle}
          color={warnings.length > 0 ? "warning" : "success"}
        />
      </Tooltip>
    </Row>
  );
}

export function MissingFieldsIndicator(
  props: MissingFieldsIndicatorProps,
): React.JSX.Element {
  const { keyValues, label, lookup } = props;

  const warnings = useGetMissingFieldWarnings({
    modelIds: keyValues,
    lookup,
  });

  return <MissingFieldsIndicatorBase label={label} warnings={warnings} />;
}
