import React from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Badge from "components/Badge";
import { GroupedCard } from "components/GroupedCard";
import { Column, Row } from "components/Layout";
import { NASMissingFieldsIndicator } from "components/MissingFieldsIndicator/NasMissingFieldsIndicator";

import { ExportSites } from "constants/exportSites";
import { SaleLotModalSection } from "constants/navigation";
import { auctionPenStatuses } from "constants/sale";

import { ForSaleyardAdmin } from "containers/ForUserType";

import { getOverridePenStatus } from "lib/auctionPens";
import { openEditSaleLotModal } from "lib/navigation";

import {
  getAgencyShortNameByAuctionPenId,
  getAuctionPenById,
  getSaleLotById,
  getSaleLotIdsByAuctionPenId,
  getStatusByAuctionPenId,
} from "selectors";

import { useToggle } from "hooks";

import Actions from "./Actions";
import { AgencyName } from "./elements";
import GroupSummary from "./GroupSummary";
import ReorderPen from "./ReorderPen";
import RepenMultiple from "./RepenMultiple";
import SaleLotsGroup from "./SaleLotsGroup";

function AuctionPenCard({
  auctionPenId,
  isOpen,
  basePath,
  onExpand,
  weighedOnly,
  showSelect = false,
}) {
  const penStatus = useSelector(getStatusByAuctionPenId(auctionPenId));
  const auctionPen = useSelector(getAuctionPenById(auctionPenId));

  // Force the status of the unpenned pen (auctionPenId null) to be ... unpenned (Count is a higher status, so was trumping it)
  const status = getOverridePenStatus(auctionPen, auctionPenId, penStatus);
  const agencyShortName = useSelector(
    getAgencyShortNameByAuctionPenId(auctionPenId),
  );

  const saleLotIds = useSelector(getSaleLotIdsByAuctionPenId(auctionPenId));

  const [repenIsOpen, toggleRepenIsOpen] = useToggle(false);
  const [isReorderOpen, toggleIsReorderOpen] = useToggle(false);

  const toggleDisplaySaleLots = () => {
    if (isOpen) {
      onExpand(undefined);
    } else {
      onExpand(auctionPenId);
    }
  };

  const firstSaleLotId =
    Array.isArray(saleLotIds) && saleLotIds.length === 1 ? saleLotIds[0] : null;

  const firstSaleLot = useSelector(getSaleLotById(firstSaleLotId));

  const firstSaleLotIsListedWithAuctionsPlus =
    firstSaleLot &&
    firstSaleLot.exportSites &&
    firstSaleLot.exportSites.includes(ExportSites.AUCTIONS_PLUS);

  const openLotMediaTab = firstSaleLotId
    ? () => openEditSaleLotModal(firstSaleLotId, SaleLotModalSection.MEDIA)
    : null;

  const showRePenAll = status === auctionPenStatuses.NOT_SOLD && saleLotIds;

  const groupSummary = <GroupSummary auctionPenId={auctionPenId} />;
  // TODO: Add a UnpennedGroupSummary component

  const groupedItems = (
    <SaleLotsGroup
      auctionPenId={auctionPenId}
      basePath={basePath}
      weighedOnly={weighedOnly}
      showSelect={showSelect}
    />
  );
  const notPenned = status && status === auctionPenStatuses.NOT_PENNED;
  const notCounted = status && status === auctionPenStatuses.NOT_COUNTED;
  const isTempPen = status && status === auctionPenStatuses.TEMP_PEN;

  let badgeColor = status;
  if (notCounted) {
    badgeColor = "inputError";
  } else if (notPenned) {
    badgeColor = "warning";
  } else if (isTempPen) {
    badgeColor = "warning";
  }

  const actions = (
    <Row alignCenter>
      <Column>
        <Badge
          color={badgeColor}
          uppercase
          data-tour={status}
          partially={
            !![
              auctionPenStatuses.PARTIALLY_SOLD,
              auctionPenStatuses.PARTIALLY_DELIVERED,
            ].includes(status)
          }
        >
          {status}
        </Badge>
      </Column>
      <Column paddingHorizontal={2} flexGrow flex={false}>
        <ForSaleyardAdmin>
          <AgencyName>{agencyShortName}</AgencyName>
        </ForSaleyardAdmin>
      </Column>
      <NASMissingFieldsIndicator keyValue={saleLotIds} />
      <Column>
        <Actions
          auctionPenId={auctionPenId}
          onClickReorder={toggleIsReorderOpen}
          onClickRepen={showRePenAll ? toggleRepenIsOpen : undefined}
          onClickAddMedia={
            firstSaleLotIsListedWithAuctionsPlus ? openLotMediaTab : undefined
          }
        />
      </Column>
    </Row>
  );

  return (
    <>
      <GroupedCard
        status={status}
        groupSummary={groupSummary}
        topRow={actions}
        groupedItems={groupedItems}
        onClick={toggleDisplaySaleLots}
        isOpen={isOpen}
        startPen={auctionPen?.start_pen}
      />
      {repenIsOpen && (
        <RepenMultiple
          auctionPenId={auctionPenId}
          toggleRepenIsOpen={toggleRepenIsOpen}
        />
      )}
      {isReorderOpen && (
        <ReorderPen
          auctionPenId={auctionPenId}
          closeSelf={toggleIsReorderOpen}
        />
      )}
    </>
  );
}

AuctionPenCard.propTypes = {
  isOpen: PropTypes.bool,
  basePath: PropTypes.string,
  auctionPenId: PropTypes.string,
  onExpand: PropTypes.func,
};

export default React.memo(AuctionPenCard);
