import React from "react";

import { faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { Divider, Grid } from "@material-ui/core";
import { FieldArray, useFormikContext } from "formik";
import { isObject, uniq } from "lodash";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import { SlimButton } from "components/Button";
import { ButtonIcon } from "components/Button/FontAwesomeButton";
import { SaleSubTypeField } from "components/Form/Fields/SaleFilter/SaleSubTypeField";
import { SaleTypeField } from "components/Form/Fields/SaleFilter/SaleTypeField";
import { SpeciesField } from "components/Form/Fields/SaleFilter/SpeciesField";
import { Error, Input, Label, Required } from "components/Form/FormikControls";
import {
  withArrayNamespace,
  withNamespace,
} from "components/Form/FormikControls/lib";
import { Column } from "components/Layout";

import { useFieldMeta, useFieldValue } from "hooks";

export const tradingTermValidation = Yup.array()
  .nullable()
  .of(
    Yup.object().shape({
      speciesId: Yup.number().nullable(true),
      saleType: Yup.string().nullable(true),
      saleSubTypeId: Yup.number().nullable(true),
      buyerTerms: Yup.number().required("Required"),
      vendorTerms: Yup.number().required("Required"),
    }),
  )
  .test(
    "non-dupe",
    "This combination of Sale Type, Species and Sale Sub Type is not unique.",
    //
    value =>
      uniq(
        value?.map(
          tradingTerm =>
            `${tradingTerm.saleType}${tradingTerm.speciesId}${tradingTerm.saleSubTypeId}`,
        ),
      ).length === (value?.length || 0),
  );

function TradingTerm(props) {
  const { index, name, namespace, removeRow } = props;

  const ns = withArrayNamespace(withNamespace(namespace, name), index);

  const { error } = useFieldMeta(ns);

  return (
    <>
      <Grid item xs={3}>
        <SaleTypeField namespace={ns} required />
      </Grid>
      <Grid item xs={2}>
        <SpeciesField namespace={ns} required />
      </Grid>
      <Grid item xs={4}>
        <SaleSubTypeField namespace={ns} />
      </Grid>
      <Grid item xs={1}>
        <Input name={withNamespace(ns, "buyerTerms")} />
      </Grid>
      <Grid item xs={1}>
        <Input name={withNamespace(ns, "vendorTerms")} />
      </Grid>
      <Grid item xs={1} container justifyContent="flex-end">
        <SlimButton
          onClick={() => removeRow(index)}
          color="deleteRed"
          type="button"
        >
          <ButtonIcon icon={faTrash} />
          &nbsp;Remove
        </SlimButton>
      </Grid>
      {error?.rowError && (
        <Grid item xs={12}>
          <Error>{error.rowError}</Error>
        </Grid>
      )}
      <Grid xs={12} item>
        <Divider />
      </Grid>
    </>
  );
}

const generateDefaultTerm = () => ({
  id: uuidv4(),
  saleType: null,
  speciesId: null,
  saleSubTypeId: null,
  buyerTerms: 0,
  vendorTerms: 0,
});

export function TradingTerms(props) {
  const { namespace = "", name = "tradingTerms", tooltip } = props;
  const value = useFieldValue(withNamespace(namespace, name)) || [];
  const { errors } = useFormikContext();
  const error = errors[withNamespace(namespace, name)];

  return (
    <Column fullWidth fullHeight>
      <Label tooltip={tooltip} />
      <Column fullWidth fullHeight paddingHorizontal={3}>
        <FieldArray name={withNamespace(namespace, name)}>
          {arrayHelpers => {
            const addNew = () => {
              arrayHelpers.push(generateDefaultTerm());
            };
            return (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SlimButton onClick={addNew} type="button">
                    <ButtonIcon icon={faPlus} />
                    &nbsp;New Trading Terms
                  </SlimButton>
                </Grid>
                {!isObject(error) && error ? (
                  <Grid item xs={12}>
                    <Error>{error}</Error>
                  </Grid>
                ) : null}
                {value.length > 0 ? (
                  <>
                    <Grid item xs={3}>
                      <Label>Sale Type</Label>
                    </Grid>
                    <Grid item xs={2}>
                      <Label>Species</Label>
                    </Grid>
                    <Grid item xs={4}>
                      <Label>Sale Sub Type</Label>
                    </Grid>
                    <Grid item xs={1}>
                      <Label>
                        Buyer Terms
                        <Required />
                      </Label>
                    </Grid>
                    <Grid item xs={1}>
                      <Label>
                        Vendor Terms
                        <Required />
                      </Label>
                    </Grid>

                    <Grid item xs={1}>
                      &nbsp;
                    </Grid>

                    <Grid xs={12} item>
                      <Divider />
                    </Grid>
                    {value.map((tradingTerm, idx) => (
                      <TradingTerm
                        index={idx}
                        removeRow={arrayHelpers.remove}
                        name={name}
                        key={idx}
                      />
                    ))}
                  </>
                ) : null}
              </Grid>
            );
          }}
        </FieldArray>
      </Column>
    </Column>
  );
}
