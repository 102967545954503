import React from "react";

import { useSelector } from "react-redux";

import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { RequiredNutrienFieldOptions } from "constants/saleLots";

import { selectNASFieldWarningsBySaleLotId } from "selectors";

import { useHasSaleyardOrDeploymentPermission } from "hooks";
import { NASMissingFieldsIndicatorProps } from "./types";
import { MissingFieldsIndicator } from ".";

export function NASMissingFieldsIndicator(
  props: NASMissingFieldsIndicatorProps,
): React.JSX.Element {
  const { keyValue } = props;
  const hasDentitionPermission = useHasSaleyardOrDeploymentPermission(
    DeploymentPermissions.featureDentition,
    SaleyardPermissions.featureDentition,
  );

  const hasWeightRangePermission = useHasSaleyardOrDeploymentPermission(
    DeploymentPermissions.featureWeightRange,
    SaleyardPermissions.featureWeightRange,
  );

  const hasMissingNasFieldsSaleLotIdLookup = useSelector(
    selectNASFieldWarningsBySaleLotId,
  );

  if (!hasWeightRangePermission || !hasDentitionPermission) {
    return null;
  }

  let keyValues = keyValue;
  if (!Array.isArray(keyValues)) {
    keyValues = [keyValues];
  }

  return (
    <MissingFieldsIndicator
      keyValues={keyValues}
      label="NAS"
      lookup={hasMissingNasFieldsSaleLotIdLookup}
      fields={RequiredNutrienFieldOptions}
    />
  );
}
