export const SplitType = {
  STANDARD: "STANDARD",
  CRIPPLE: "CRIPPLE",
  NCV: "NCV",
  TRANSIT: "TRANSIT",
};

/* Correlates with PricingEntry TYPES in API. */
export const PricingEntryTypes = {
  TYPE_SKIN_GROSS: "Skins - Gross",
  TYPE_SKIN_PER_HEAD: "Skins - Per Head",
};

export const SkinPricingEntryTypes = [
  PricingEntryTypes.TYPE_SKIN_GROSS,
  PricingEntryTypes.TYPE_SKIN_PER_HEAD,
];

export const skinPricingOptions = [
  {
    label: "No Skins",
    value: undefined,
  },
  {
    label: PricingEntryTypes.TYPE_SKIN_GROSS,
    value: PricingEntryTypes.TYPE_SKIN_GROSS,
  },
  {
    label: PricingEntryTypes.TYPE_SKIN_PER_HEAD,
    value: PricingEntryTypes.TYPE_SKIN_PER_HEAD,
  },
];
export const KEYWORD_CATEGORY_CRIPPLE = "cripple";
export const KEYWORD_CATEGORY_NCV = "ncv";
export const KEYWORD_CATEGORY_TRANSIT = "transit";
export const KEYWORD_BUSINESS_NAME_NCV = "NCV";
export const KEYWORD_BUSINESS_NAME_TRANSIT = "TRANSIT";

// Used to determine the purpose of the attached Sale Lot Scans
export const SaleLotType = {
  AUCTION: "auction",
  SALEYARD_SCAN: "saleyard_scan",
  VENDOR_SPLIT: "Vendor Split",
};

export const SaleLotNamespaces = {
  CLEARING_SALE_ATTRIBUTES: "clearingSaleAttributes",
};

export const IGNORED_DRAFTING_ATTRIBUTES = [
  "advancedBreed1Hd",
  "advancedBreed2Hd",
  "advancedBreed3Hd",
  "animalName",
  "dentition0",
  "dentition2",
  "dentition4",
  "dentition6",
  "dentition8",
  "dentitionBroken",
  "fatScore1",
  "fatScore2",
  "fatScore3",
  "fatScore4",
  "fatScore5",
  "fatScore6",
  "hornDehorned",
  "hornHorned",
  "hornPolled",
  "hornScurred",
  "hornTipped",
  "maxMassGrams",
  "minMassGrams",
  "tagNumber",
];

export const RequiredNutrienFields = {
  DENTITION: "dentitionId",
  WEIGHT_RANGE: "estimatedAverageWeightId",
};

export const RequiredNutrienFieldOptions = [
  { label: "Dentition", value: RequiredNutrienFields.DENTITION },
  { label: "Weight Range", value: RequiredNutrienFields.WEIGHT_RANGE },
];
