import React, { useEffect, useMemo, useRef } from "react";

import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { TextField } from "@material-ui/core";
import {
  Autocomplete as MuiAutocomplete,
  createFilterOptions,
} from "@material-ui/lab";
import { useField } from "formik";
import styled from "styled-components";

import IconTextButton from "components/Button/IconTextButton";
import { DataTour } from "components/DataTour";
import { Column } from "components/Layout";
import { SmallText } from "components/Text";

import { Error } from "./Error";
import { Label } from "./Label";

// Standard filter doesn't allow creating or limiting the options displayed.
export const standardFilter = createFilterOptions();

export const FILTER_LIMIT = 50;

export const getOptionAsValue = option => option?.value;

const defaultFilterOptions = (options, params) => {
  let filtered = standardFilter(options, params).filter(o => !o.hide);

  if (filtered.length > FILTER_LIMIT) {
    filtered = [
      {
        disabled: true,
        label: `Showing ${FILTER_LIMIT} of ${filtered.length} options`,
      },
      ...filtered.slice(0, FILTER_LIMIT),
    ];
  }

  // Suggest the creation of a new value
  if (params.inputValue !== "") {
    filtered.push({
      inputValue: params.inputValue,
      label: `Add "${params.inputValue}"`,
    });
  }

  return filtered;
};

const AutocompleteSuggestedValue = ({
  valueFieldName,
  suggestedValueFieldName,
  options,
  getOptionValue,
  getOptionLabel,
}) => {
  const [valueField, ignored, helpers] = useField(valueFieldName);
  const { setValue, setTouched } = helpers;

  const [suggestedValueField] = useField(suggestedValueFieldName);

  const suggestedValue =
    suggestedValueField &&
    suggestedValueField &&
    suggestedValueField.value !== valueField.value
      ? suggestedValueField.value
      : undefined;

  const suggestedLabel = useMemo(() => {
    if (suggestedValue) {
      const suggestedOption = options.find(
        o => getOptionValue(o) === suggestedValue,
      );

      if (suggestedOption) {
        return getOptionLabel(suggestedOption);
      }
    }
    return "";
  }, [getOptionLabel, getOptionValue, options, suggestedValue]);

  const handleClickUseSuggestedValue = () => {
    setValue(suggestedValue);
    setTouched(true);
  };

  if (suggestedLabel) {
    return (
      <IconTextButton
        icon={faQuestionCircle}
        color="success"
        onClick={handleClickUseSuggestedValue}
      >
        Use Suggested: {suggestedLabel}
      </IconTextButton>
    );
  }
};

const defaultGetOptionDisabled = option => option?.disabled;
const defaultGetOptionAsValue = option => option;
const defaultGetOptionLabel = option => option.label;

export function defaultOnChange(event, newValue, props) {
  const {
    getOptionValue = defaultGetOptionAsValue,
    handleNew,
    multiple,
    onChangeExtra,
    setTouched,
    setValue,
  } = props;

  if (typeof newValue === "string") {
    typeof handleNew === "function" && handleNew(newValue);
  } else if (newValue && newValue.inputValue) {
    typeof handleNew === "function" && handleNew(newValue.inputValue);
  } else {
    const toSet = multiple
      ? (newValue || []).map(v => getOptionValue(v))
      : getOptionValue(newValue) || null;
    setTouched(true, false).then(() => setValue(toSet, true));
    typeof onChangeExtra === "function" && onChangeExtra(toSet);
  }
}

function defaultGetSelectedOption(value, props) {
  const { multiple, options, getOptionValue = defaultGetOptionAsValue } = props;
  if (multiple) {
    if (Array.isArray(value)) {
      return options.filter(o => value.includes(getOptionValue(o)));
    }
    return [];
  } else {
    return options.find(o => getOptionValue(o) === value) || value || null;
  }
}

const UnderlinedTextField = styled(TextField)`
  & .MuiInput-underline:after {
    border: 2px solid #427db3;
  }
`;

export const renderOptionWithDescription = ({ label, description }) => {
  return (
    <div>
      {label}
      <br />
      <SmallText>{description}</SmallText>
    </div>
  );
};

export const Autocomplete = props => {
  const {
    autoSelect,
    autoFocus = false,
    clearOnBlur,
    disableCloseOnSelect = false,
    disableClearable = false,
    disabled = false,
    filterOptions = defaultFilterOptions,

    freeSolo = false,
    getOptionLabel: fieldGetOptionLabel = defaultGetOptionLabel,
    getOptionDisabled = defaultGetOptionDisabled,
    getOptionSelected,
    getOptionValue = defaultGetOptionAsValue,
    getSelectedOption = defaultGetSelectedOption,
    groupBy,
    handleHomeEndKeys,
    onChangeExtra = undefined,
    label,
    multiple = false,
    name,
    dataTour,
    onChange = defaultOnChange,
    onClose,
    options,
    // handleNew = undefined,
    placeholder = undefined,
    renderOption = undefined,
    required = false,
    selectOnFocus,
    suggestedValueFieldName,
    tooltip = "",
    renderTags,
    classes,
    inlineButton = null,
    PopperComponent = undefined,
    ListboxComponent = undefined,
  } = props;
  const [field, meta, helpers] = useField(name);
  const { setValue, setTouched } = helpers;

  const { value } = field;

  const selectedOption = getSelectedOption(value, props);

  const onChangeInternal = (e, newValue) => {
    onChange(e, newValue, { ...props, setValue, setTouched, onChangeExtra });
  };

  const error =
    meta.touched &&
    meta.error &&
    (typeof meta.error === "string"
      ? meta.error
      : Object.values(meta.error)
          .map(error => error)
          .toString());

  const getOptionLabel = option =>
    (typeof fieldGetOptionLabel === "function" &&
      fieldGetOptionLabel(option)) ||
    option.label ||
    "";

  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <Column fullWidth>
      <DataTour dataTour={dataTour || name}>
        <Label
          htmlFor={name}
          required={!!label && required}
          tooltip={tooltip}
          error={!!error}
          inlineButton={inlineButton}
        >
          {label}
        </Label>
        <MuiAutocomplete
          autoSelect={autoSelect}
          autoHighlight
          clearOnBlur={clearOnBlur}
          disabled={disabled}
          disableClearable={disableClearable}
          disableCloseOnSelect={disableCloseOnSelect}
          filterOptions={filterOptions}
          freeSolo={freeSolo}
          getOptionSelected={getOptionSelected}
          groupBy={groupBy}
          getOptionDisabled={getOptionDisabled}
          getOptionLabel={getOptionLabel}
          handleHomeEndKeys={handleHomeEndKeys}
          multiple={multiple}
          onChange={onChangeInternal}
          onClose={onClose}
          options={options}
          value={selectedOption}
          renderTags={renderTags}
          renderInput={params => (
            <UnderlinedTextField
              {...params}
              placeholder={placeholder}
              inputRef={inputRef}
            />
          )}
          renderOption={renderOption}
          selectOnFocus={selectOnFocus}
          classes={classes}
          PopperComponent={PopperComponent}
          ListboxComponent={ListboxComponent}
        />
        {error && <Error>{error}</Error>}
        {suggestedValueFieldName && (
          <AutocompleteSuggestedValue
            valueFieldName={name}
            suggestedValueFieldName={suggestedValueFieldName}
            options={options}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
          />
        )}
      </DataTour>
    </Column>
  );
};
