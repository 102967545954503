import { RequiredNutrienFieldOptions } from "constants/saleLots";
import { useFormikContext } from "formik";
import { flatten, isEmpty, uniq } from "lodash";

interface UseGetMissingFieldWarningsProps {
  modelIds: string[];
  lookup: Object;
}

export const useGetMissingFieldWarnings = ({
  modelIds,
  lookup = {},
}: UseGetMissingFieldWarningsProps): string[] => {
  const { values } = useFormikContext() || {};
  let uniqueMissingFields = uniq(
    flatten(modelIds.map(modelId => lookup[modelId])),
  );

  // Check if the missing fields are in formik, if they are then they have set them, so remove the associated warning.
  if (!isEmpty(values)) {
    const filteredWarnings = uniqueMissingFields.filter(warning =>
      RequiredNutrienFieldOptions.some(
        field => field.label === warning && values[field.value],
      ),
    );
    uniqueMissingFields = uniqueMissingFields.filter(
      val => !filteredWarnings.includes(val),
    );
  }
  return uniqueMissingFields;
};
