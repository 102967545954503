import React from "react";

import { Form, Formik, useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import * as Yup from "yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import {
  BUSINESS_RELATION_OPTIONS,
  businessRelationsRateTypes,
  businessRelationsRateTypesAsOptions,
} from "constants/businesses";
import { BusinessFieldWithFilter } from "components/Form/Fields";
import { Button, DeleteButton, SecondaryButton } from "components/Form";
import {
  Autocomplete,
  ControlledLifeCycleInput,
  DollarDisplayCentsInputProps,
  getOptionAsValue,
  Label,
  PercentageInputProps,
  renderOptionWithDescription,
  standardFilter,
} from "components/Form/FormikControls";

import { useFieldValue } from "hooks";
import { validatePercentage } from "lib/validators";
import { SaleTypeField } from "components/Form/Fields/SaleFilter/SaleTypeField";
import { SpeciesField } from "components/Form/Fields/SaleFilter/SpeciesField";
import { SaleSubTypeField } from "components/Form/Fields/SaleFilter/SaleSubTypeField";
import { Divider } from "@material-ui/core";

interface InitialValuesProps {
  id: any;
  relatedToId: string;
  relationType: string;
  rateValue: number;
  rateType: string;
  saleType: string;
  saleSubTypeId: number;
  speciesId: number;
}

interface BusinessRelationshipDialogProps {
  initialValues: InitialValuesProps;
  isOpen: boolean;
  handleSubmit: (object) => void;
  handleClose: () => void;
  handleDelete?: () => void;
}

interface BusinessRelationshipFormProps {
  initialValues: InitialValuesProps;
  handleClose: () => void;
  handleDelete?: () => void;
}

const RateValueInput = () => {
  const type = useFieldValue("rateType");

  const amountProps =
    type === businessRelationsRateTypes.HEAD
      ? DollarDisplayCentsInputProps
      : PercentageInputProps;

  return (
    <ControlledLifeCycleInput name="rateValue" label="Value" {...amountProps} />
  );
};

const validationSchema = Yup.object().shape({
  relatedToId: Yup.string().nullable().required("Required"),
  relationType: Yup.string().nullable().required("Required"),
  rateValue: validatePercentage(12, 6),
  rateType: Yup.string().nullable().required("Required"),
});

function BusinessRelationshipForm({
  handleDelete,
  handleClose,
  initialValues,
}: BusinessRelationshipFormProps) {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const clearRateValue = () => {
    setFieldValue("rateValue", null);
    setFieldTouched("rateValue");
  };

  return (
    <Form data-tour="businessRelationshipForm">
      <DialogTitle
        onClose={handleClose}
      >{`${initialValues.id ? "Edit" : "Add"} Relationship`}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              name="relationType"
              label="Relation Type"
              options={BUSINESS_RELATION_OPTIONS}
              renderOption={renderOptionWithDescription}
              getOptionValue={getOptionAsValue}
              filterOptions={standardFilter}
            />
          </Grid>
          <Grid item xs={12}>
            <BusinessFieldWithFilter name="relatedToId" label="Business" />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              name="rateType"
              label="Rate Type"
              options={businessRelationsRateTypesAsOptions}
              getOptionValue={getOptionAsValue}
              filterOptions={standardFilter}
              onChangeExtra={clearRateValue}
            />
          </Grid>
          <Grid item xs={12}>
            <RateValueInput />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Label tooltip="Set Relationship per type of Sale. Sale Sub Type will be preferred over Species, then Sale Type, then the default, if set.">
              Apply to Sales
            </Label>
          </Grid>

          <Grid item xs={12} md={4}>
            <SaleTypeField label="Sale Type" />
          </Grid>
          <Grid item xs={12} md={4}>
            <SpeciesField label="Species" />
          </Grid>

          <Grid item xs={12} md={4}>
            <SaleSubTypeField label="Sale Sub Type" />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {handleDelete && (
          <DeleteButton data-tour="delete" onClick={handleDelete}>
            Delete
          </DeleteButton>
        )}
        <SecondaryButton data-tour="cancel" onClick={handleClose}>
          Cancel
        </SecondaryButton>
        <Button data-tour="save" type="submit">
          Save
        </Button>
      </DialogActions>
    </Form>
  );
}

export const BusinessRelationshipDialog = ({
  initialValues,
  isOpen,
  handleSubmit,
  handleClose,
  handleDelete,
}: BusinessRelationshipDialogProps) => (
  <Dialog open={isOpen} maxWidth="md" fullWidth onClose={handleClose}>
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <BusinessRelationshipForm
        handleDelete={handleDelete}
        handleClose={handleClose}
        initialValues={initialValues}
      />
    </Formik>
  </Dialog>
);
