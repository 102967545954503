import React from "react";

import { faHashtag, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import Badge from "components/Badge";
import StatusCard from "components/Card/StatusCard";
import {
  ChipBag,
  AccreditationsChips,
  AngusVerifiedSaleLotChip,
  DeliveryPenChip,
  DraftChips,
  SaleLotEidsNotRegisteredChip,
  ERPChip,
  LabelChip,
  MarkChips,
  ImagesChip,
  VideosChip,
  AlternativeMoneyChip,
  MoneyChip,
  FullOrPartial,
  NoteChip,
  PICChip,
  StudSaleChip,
  VendorBredChip,
  AverageWeightChip,
  ManuallyWeighedChip,
  TotalWeightChip,
  SpeciesChippies,
} from "components/Chip";
import { UnresolvedCommentIcon } from "components/Comments/Icon";
import { LargeFaIcon } from "components/FaIcon";
import { RightActionColumn } from "components/GroupedCard";
import { IntegrationIcon } from "components/Icons";
import { Row } from "components/Layout";
import { NASMissingFieldsIndicator } from "components/MissingFieldsIndicator/NasMissingFieldsIndicator";
import PenSummary from "components/PenSummary";
import { ResponsiveText } from "components/ResponsiveText";
import ScannedCount from "components/ScannedCount";
import { BoldText, StatusText, Subtitle } from "components/Text";

import { PricingTypes, pricingTypeString } from "constants/pricingTypes";
import { saleLotStatuses } from "constants/sale";

import { SaleLotActionOptions } from "containers/ActionOptions";
import {
  ForHooks,
  ForLivestockAuction,
  ForLivestockSale,
} from "containers/ForSaleType";
import { ForCattle } from "containers/ForSpecies";
import {
  ForEveryoneExceptBusinessUsers,
  ForEveryoneExceptBusinessUsersAndSaleWatcher,
  ForSaleyardAdmin,
} from "containers/ForUserType";

import { getCombinedLotNumber, getProgenyDisplayCount } from "lib/saleLot";

import {
  getCurrentSale,
  getFormattedSaleLotBySaleLotId,
  getHasDentitionExceptionBySaleLotId,
  getHasDentitionWarningBySaleLotId,
  getHasWeightRangeExceptionBySaleLotId,
  getHasWeightRangeWarningBySaleLotId,
  getIsSaleyardAdmin,
  getIsVendorBredBySaleLotId,
  getSaleLotById,
  getUnresolvedSaleLotCommentCountBySaleLotId,
  selectHasAgeExceptionBySaleLotId,
  selectHasAgeWarningBySaleLotId,
  selectHasBreedExceptionBySaleLotId,
  selectHasBreedWarningBySaleLotId,
  selectHasSexExceptionBySaleLotId,
  selectHasSexWarningBySaleLotId,
} from "selectors";

const HeadCountNumber = styled.span`
  font-size: 18px;
  margin-right: 10px;
  font-weight: bold;
`;

const LotInfo = styled(BoldText)(
  ({ theme, labelColor, marginLeft }) => `
   color: ${labelColor || theme.colors.gray78};
   margin-right: 10px;
   ${marginLeft ? `margin-left: ${marginLeft};` : ""}
`,
);

const ProgenyText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.alpha}px;
  display: inline;
  margin-right: ${({ theme }) => theme.space[1]}px;
  white-space: nowrap;
`;

const LotCard = props => {
  const {
    basePath,
    onClickCard,
    saleLot,
    showActions,
    showBuyer,
    showBuyerWay,
    showPen,
    showScanning,
    showVendor,
    showCommercialData = true,
    overrideStatus = null,
    faded = false,
  } = props;
  const {
    id,
    ageName,
    agencyShortName,
    bidder = {},
    breedName,
    EIDNLISDetails,
    endPen,
    lotNumberCombined,
    note,
    overflowPen,
    overflowQuantity,
    quantity,
    quantityProgeny,
    quantityUndelivered,
    startPen,
    status: lotStatus,
    scannedCount,
    scannedPercentage,
    scannedStatus,
    saleRoundName,
    sexName,
    gradeName,
    categoryName,
    priceUnits,
    price,
    marks,
    vendorName,
    buyerName,
    buyerWayName,
    destinationProperty,
    totalMassGrams,
    averageWeightFormatted,
    totalWeightFormatted,
    youtube_link,
    imageCount,
  } = saleLot;

  const lotNumber = lotNumberCombined || getCombinedLotNumber(saleLot);

  const showBidderNumber =
    useSelector(getCurrentSale)?.using_registered_bidders;

  const status = overrideStatus || lotStatus;

  const { ERPStatus, EUStatus, lifetimeTraceable } = EIDNLISDetails;

  const { registrationNumber: bidderNumber } = bidder;

  const vendorBred = useSelector(getIsVendorBredBySaleLotId(id));

  const erpMessages = Array.from(ERPStatus);

  const unresolvedSaleLotComments = useSelector(
    getUnresolvedSaleLotCommentCountBySaleLotId(id),
  );

  const hasBreedException = useSelector(selectHasBreedExceptionBySaleLotId)[
    saleLot.id
  ];
  const hasBreedWarning = useSelector(selectHasBreedWarningBySaleLotId)[
    saleLot.id
  ];
  const hasSexException = useSelector(selectHasSexExceptionBySaleLotId)[
    saleLot.id
  ];
  const hasSexWarning = useSelector(selectHasSexWarningBySaleLotId)[saleLot.id];

  const hasAgeException = useSelector(selectHasAgeExceptionBySaleLotId)[
    saleLot.id
  ];
  const hasWeightRangeException = useSelector(
    getHasWeightRangeExceptionBySaleLotId(saleLot.id),
  );

  const hasWeightRangeWarning = useSelector(
    getHasWeightRangeWarningBySaleLotId(saleLot.id),
  );
  const hasDentitionWarning = useSelector(
    getHasDentitionWarningBySaleLotId(saleLot.id),
  );

  const hasDentitionException = useSelector(
    getHasDentitionExceptionBySaleLotId(saleLot.id),
  );

  const hasAgeWarning = useSelector(selectHasAgeWarningBySaleLotId)[saleLot.id];

  const notPenned = status && status === saleLotStatuses.NOT_PENNED;
  const notCounted = status && status === saleLotStatuses.NOT_COUNTED;
  let labelColor = null;
  let badgeColor = status;
  if (notCounted) {
    labelColor = "#00000095";
    badgeColor = "inputError";
  } else if (notPenned) {
    labelColor = "#00000095";
    badgeColor = "warning";
  }

  const missingInfo = [];

  const isSaleyardAdmin = useSelector(getIsSaleyardAdmin);

  if (hasBreedException || hasBreedWarning) {
    missingInfo.push("Breed");
  }

  if (hasSexException || hasSexWarning) {
    missingInfo.push("Sex");
  }

  if (hasAgeException || hasAgeWarning) {
    missingInfo.push("Age");
  }

  if (destinationProperty === "" && buyerName === "") {
    missingInfo.push("PIC");
  }

  if ((hasWeightRangeException || hasWeightRangeWarning) && !isSaleyardAdmin) {
    missingInfo.push("Estimated Avg. Weight");
  }

  if ((hasDentitionException || hasDentitionWarning) && !isSaleyardAdmin) {
    missingInfo.push("Dentition");
  }

  // Will only check against Livestock Sale (Not Clearing Sale) Pricing Type display names
  priceUnits === pricingTypeString()(PricingTypes.PER_KILO) &&
    +price > 0 &&
    !totalMassGrams &&
    missingInfo.push("weight");

  const hasVideo = !!youtube_link;

  const offlinePatching = useSelector(
    state => getSaleLotById(saleLot.id)(state)?.syncing,
  );
  return (
    <StatusCard
      data-tour={`statusCard.${lotNumber}`}
      rounded
      status={offlinePatching ? "inputError" : status}
      onClick={onClickCard}
      faded={faded}
    >
      <Row justifyBetween>
        <Row justifyStart alignCenter fullWidth>
          <Badge
            uppercase
            bold
            style={{ maxWidth: "60%" }}
            color={badgeColor}
            data-tour={status}
          >
            {status}
          </Badge>
          <UnresolvedCommentIcon
            unresolvedCommentCount={unresolvedSaleLotComments}
            horizontalPadding={1}
            size="2x"
          />
          <LotInfo
            labelColor={labelColor}
            data-tour="lotNum"
            marginLeft={unresolvedSaleLotComments ? null : "8px"}
          >
            Lot <FontAwesomeIcon icon={faHashtag} />
            {lotNumber}
          </LotInfo>
          <ForLivestockAuction>
            <LotInfo labelColor={labelColor} data-tour="round">
              Round: {startCase(saleRoundName)}
            </LotInfo>
          </ForLivestockAuction>
          <ForSaleyardAdmin>
            <LotInfo>Agency: {agencyShortName}</LotInfo>
          </ForSaleyardAdmin>
        </Row>

        <RightActionColumn>
          <ForEveryoneExceptBusinessUsers>
            <Row justifyEnd>
              <NASMissingFieldsIndicator keyValue={id} />
              {showActions && (
                <SaleLotActionOptions
                  saleLotId={saleLot.id}
                  basePath={basePath}
                />
              )}
            </Row>
          </ForEveryoneExceptBusinessUsers>
        </RightActionColumn>
      </Row>

      <Grid container spacing={2}>
        <ForLivestockAuction>
          {showPen && (
            <Grid item xs sm md>
              <PenSummary
                startPen={startPen}
                endPen={endPen}
                overflowPen={overflowPen}
                overflowQuantity={overflowQuantity > 0 ? overflowQuantity : 0}
                labelColor={labelColor}
              />
            </Grid>
          )}
        </ForLivestockAuction>
        <ForEveryoneExceptBusinessUsers>
          {showVendor && (
            <Grid item xs={6} sm={showBuyer ? 3 : 8} md>
              <Subtitle labelColor={labelColor}>Vendor</Subtitle>
              <BoldText>{vendorName || "-"}</BoldText>
            </Grid>
          )}
        </ForEveryoneExceptBusinessUsers>
        {showBuyer && (
          <Grid item xs={6} sm={showVendor ? 3 : 8} md>
            <Subtitle labelColor={labelColor}>Buyer</Subtitle>
            <BoldText>
              {buyerName || "-"} {showBuyerWay && buyerWayName}{" "}
              {showBidderNumber && bidderNumber && ` - ${bidderNumber}`}
            </BoldText>
          </Grid>
        )}
        <Grid item xs sm md>
          <Subtitle labelColor={labelColor}>
            <ResponsiveText mobile="Hd" tablet="Hd" desktop="Head Count" />
          </Subtitle>

          <HeadCountNumber data-tour="headCount">
            {quantity || "?"}
          </HeadCountNumber>
          <ProgenyText>
            {quantityProgeny > 0
              ? getProgenyDisplayCount(quantityProgeny)
              : null}
          </ProgenyText>

          {quantityUndelivered !== 0 && (
            <Badge color="warning">
              {quantityUndelivered < 0 && "+"}
              {-quantityUndelivered}
            </Badge>
          )}
        </Grid>
        <ForLivestockSale>
          {showScanning && (
            <Grid item xs sm md>
              <Subtitle data-tour="scanned" labelColor={labelColor}>
                EIDs
              </Subtitle>
              <ScannedCount
                count={scannedCount}
                percentage={scannedPercentage}
                status={scannedStatus}
              />
            </Grid>
          )}
        </ForLivestockSale>

        <Grid item xs={12}>
          <ChipBag>
            <SpeciesChippies
              ageName={ageName}
              breedName={breedName}
              categoryName={categoryName}
              gradeName={gradeName}
              sexName={sexName}
            />
            {totalWeightFormatted && (
              <TotalWeightChip>
                Tot. Wt. {totalWeightFormatted} Kg
              </TotalWeightChip>
            )}
            {averageWeightFormatted && (
              <AverageWeightChip>
                Avg. Wt. {averageWeightFormatted} Kg
              </AverageWeightChip>
            )}
            <ManuallyWeighedChip saleLotId={saleLot.id} />
            <MarkChips marks={marks} />
            <DraftChips saleLotId={saleLot.id} />
            {!isEmpty(destinationProperty) && (
              <PICChip
                businessId={saleLot.buyerId}
                PICs={[destinationProperty]}
              />
            )}
            <ForEveryoneExceptBusinessUsersAndSaleWatcher>
              <NoteChip note={note} />
            </ForEveryoneExceptBusinessUsersAndSaleWatcher>
            <ForHooks>
              {saleLot.weight > 0 && (
                <MoneyChip>
                  {saleLot.weight} Kg (Avg {saleLot.averageWeight})
                </MoneyChip>
              )}
              {saleLot.totalPrice > 0 && showCommercialData && (
                <MoneyChip>
                  ${saleLot.totalPrice} (Hd ${saleLot.pricePerHead})
                </MoneyChip>
              )}
            </ForHooks>
            <SaleLotEidsNotRegisteredChip saleLotId={saleLot.id} />
            <ForCattle>
              <FullOrPartial
                label="EU Eligible"
                values={EUStatus}
                positive="Y"
              />
            </ForCattle>
            <VendorBredChip value={vendorBred} />
            <FullOrPartial label="LT" values={lifetimeTraceable} positive="Y" />
            {price > 0 && showCommercialData && (
              <>
                <MoneyChip>
                  {price} {priceUnits}
                </MoneyChip>
                <AlternativeMoneyChip saleLot={saleLot} />
              </>
            )}
            {saleLot.labels.map(labelId => (
              <LabelChip labelId={labelId} key={labelId} />
            ))}

            <ImagesChip count={imageCount} />
            <VideosChip hasVideo={hasVideo} />
            <AccreditationsChips saleLotId={saleLot.id} />
            <StudSaleChip saleLotId={saleLot.id} />
            <ERPChip erpMessages={erpMessages} />
            <DeliveryPenChip saleLotIds={[saleLot.id]} />
            <AngusVerifiedSaleLotChip saleLotId={saleLot.id} />
          </ChipBag>
        </Grid>
      </Grid>
      <Row justifyBetween>
        <Row alignCenter style={{ paddingTop: 6 }}>
          {(missingInfo.length > 0 || offlinePatching) && (
            <StatusText status="error" />
          )}
          {missingInfo.length > 0 && (
            <div style={{ color: "#666", opacity: 0.5, fontStyle: "italic" }}>
              missing {missingInfo.join(", ")}
            </div>
          )}
          {offlinePatching && (
            <div style={{ color: "#FF0000", paddingLeft: 12 }}>
              Changes Pending
            </div>
          )}
        </Row>
        {offlinePatching && (
          <Row>
            <LargeFaIcon icon={faTimes} color="error" />
          </Row>
        )}
        <ForEveryoneExceptBusinessUsers>
          <Row>
            <IntegrationIcon saleLotId={saleLot.id} />
          </Row>
        </ForEveryoneExceptBusinessUsers>
      </Row>
    </StatusCard>
  );
};
LotCard.propTypes = {
  basePath: PropTypes.string,
  onClickCard: PropTypes.func,
  showActions: PropTypes.bool,
  showBuyer: PropTypes.bool,
  showBuyerWay: PropTypes.bool,
  showCommercialData: PropTypes.bool,
  showPen: PropTypes.bool,
  showScanning: PropTypes.bool,
  showVendor: PropTypes.bool,
  saleLot: PropTypes.object.isRequired,
};

LotCard.defaultProps = {
  showScanning: false,
  showPen: false,
  showBuyer: false,
  showVendor: false,
  showBuyerWay: false,
  showActions: true,
  showCommercialData: true,
};

export function LotCardBySaleLotId({
  saleLotId,
  basePath,
  cardType,
  onClickCard,
  showActions,
  showBuyer,
  showBuyerWay,
  showPen,
  showScanning,
  showVendor,
  showCommercialData,
  overrideStatus = null,
  faded = false,
}) {
  const saleLot = useSelector(getFormattedSaleLotBySaleLotId(saleLotId));
  return (
    <LotCard
      saleLot={saleLot}
      basePath={basePath}
      cardType={cardType}
      onClickCard={onClickCard}
      showActions={showActions}
      showBuyer={showBuyer}
      showBuyerWay={showBuyerWay}
      showPen={showPen}
      showScanning={showScanning}
      showVendor={showVendor}
      showCommercialData={showCommercialData}
      overrideStatus={overrideStatus}
      faded={faded}
    />
  );
}

export default LotCard;
