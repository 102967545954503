import React, { useLayoutEffect, useRef } from "react";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { SubtleBadge } from "components/Badge";
import { HideShowButton } from "components/Button";
import Collapse from "components/Collapse";
import { Row } from "components/Layout";

// Bit of a workaround for the issue discussed here
// https://github.com/mui-org/material-ui/issues/7466
export const FormGrid = styled(Grid)`
  && {
    padding: 0 ${({ spacing }) => `${(spacing / 2) * 8}px`};
    margin: 0;
    max-width: 100%;
  }
`;
export const FormSectionGrid = styled(FormGrid)`
  && {
    flex-grow: 0;
    flex-basis: 100%;
  }
`;

const CollapseWrapper = styled.div`
  margin: 0 -12px;
`;

export const HeaderRow = styled(Row)`
  margin: 0 -12px;
  padding: ${({ theme }) => `${theme.space[1]}px ${theme.space[2]}px`};
  ${({ hasErrorBadge }) => hasErrorBadge && `background-color: #FFF4EE;`};
`;

export const HeaderText = styled.span`
  margin-left: 12px;
`;

export const FormCollapse = ({
  isOpen,
  onToggle,
  header,
  children,
  hasErrorBadge,
  text = undefined,
  id = 0,
  dataTour = "formCollapse",
}) => {
  const headerRef = useRef();
  useLayoutEffect(() => {
    if (headerRef.current && isOpen) {
      // Wait 250ms for the collapse to expand then scroll to the collapse header into view.
      setTimeout(() => {
        if (headerRef.current && isOpen) {
          headerRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        }
      }, 250);
    }
  }, [isOpen, headerRef]);

  const showCollapse = Boolean(isOpen);

  return (
    <>
      <HeaderRow
        justifyBetween
        alignCenter
        hasErrorBadge={hasErrorBadge}
        onClick={onToggle}
        data-tour={dataTour}
        id={id}
        ref={headerRef}
      >
        <HeaderText>{header}</HeaderText>
        {typeof onToggle === "function" && (
          <HideShowButton isOpen={showCollapse} text={text} />
        )}
      </HeaderRow>
      <CollapseWrapper data-tour={`${dataTour}Section`}>
        <Collapse isOpen={showCollapse}>
          <FormSectionGrid container spacing={2}>
            {children}
          </FormSectionGrid>
        </Collapse>
      </CollapseWrapper>
    </>
  );
};

FormCollapse.propTypes = {
  children: PropTypes.node,
  hasErrorBadge: PropTypes.bool,
  header: PropTypes.any,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  text: PropTypes.string,
};

FormCollapse.defaultProps = {
  isOpen: true,
  hasErrorBadge: false,
};

export const CollapseLabel = styled.span(
  ({ theme }) => `
  font-size: ${theme.fontSizes.alpha}px;
  font-weight: ${theme.fontWeights.bold};
  margin: 0 ${theme.space[3]}px;
  align-self: center;
  display: flex;
`,
);

export const CollapseHeader = ({ title, children }) => {
  return (
    <Row>
      <SubtleBadge>{title}</SubtleBadge>
      {children && <CollapseLabel>{children}</CollapseLabel>}
    </Row>
  );
};
